import { DELETE_PERMISSION, CONTAINER_FILLING_SAMPLEPROCESSING } from "@kraftheinz/common";
import { S as SAMPLE_STATUS_COLORS, a as SAMPLE_STATUS_CLASS_COLORS, b as SAMPLE_STATUS_TEXTS } from "./sample.status.js";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Sample Processing", "search-by": "SampleNo,ProdLine,Container,Item", "ph-search-by": "Search by Sample No, Production Line, Container Sampled, Item Code", "create-button-text": "Add Sample", "actions-width": 84, "has-advanced-search": false }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("div", { staticClass: "status-filter mr-2" }, [_c("select-filter", { attrs: { "data-source": _vm.dataStatusFilter, "source": "code", "source-label": "description", "default-active-first-option": "", "option-filter": _vm.filterIsInactiveOption }, on: { "change": _vm.onStatusFilterChange } })], 1), _c("div", { staticClass: "d-flex align-items-center justify-content-center h-100" }, [_c("a-button", { attrs: { "type": _vm.buttonType, "align": "center", "width": 85 }, on: { "click": function($event) {
      return _vm.onShowAnalysisCompleteClick();
    } } }, [_vm._v("Show Analysis Complete")])], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.delete) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mr-1", attrs: { "icon": record.isInactive ? "eye" : "eye-invisible", "size": "small", "type": "link", "disabled": !_vm.can(_vm.permissions.delete) || record.analysisComplete || !_vm.allowDeactive }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onEdit(record);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "SampleNo", attrs: { "base-url": "/container-filling/sample-processing", "data-index": "sampleNo", "title": "Sample No", "query": _vm.customQuery, "sorter": true } }), _c("text-field", { key: "ProdLine", attrs: { "data-index": "prodLine", "title": "Production Line", "width": 140, "sorter": true } }), _c("text-field", { key: "Container", attrs: { "data-index": "container", "title": "Container Sampled", "width": 160, "sorter": true } }), _c("text-field", { key: "Item", attrs: { "data-index": "item", "title": "Item Code", "width": 120, "sorter": true } }), _c("text-field", { key: "SampleDate", attrs: { "data-index": "sampleDate", "title": "Sample Time", "parse": _vm.parseSampleDate, "width": 170 } }), _c("tag-field", { key: "Status", attrs: { "data-index": "status", "title": "Status", "width": 100, "color": _vm.getStatusColor, "parse": _vm.parseStatus } }), _c("check-box-field", { key: "AnalysisComplete", attrs: { "data-index": "analysisComplete", "title": "Analysis Complete", "align": "center", "width": 150 } }), _c("check-box-field", { key: "Interfaced", attrs: { "data-index": "interfaced", "title": "Interfaced", "align": "center", "width": 150 } })], 1);
};
var staticRenderFns$1 = [];
var ListSampleProcessing_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ListSampleProcessing",
  inject: ["crud", "can"],
  data() {
    return {
      permissions: {
        delete: DELETE_PERMISSION
      },
      customUrlStatus: `${apiUrl$1}/cf/common/status`,
      dataStatusFilter: [
        { code: "all", description: "All Status", color: "black" }
      ],
      styleObject: {
        fontSize: "16px"
      },
      apiUrl: apiUrl$1,
      allowDeactive: false
    };
  },
  computed: {
    buttonType() {
      let type = this.crud.getFilter("AnalysisComplete");
      return type ? "primary" : "default";
    }
  },
  created() {
    this.crud.setFilter("Interfaced", { operator: "Eq", value: "false" });
    if (this.buttonType == "primary") {
      this.crud.deleteFilter("Interfaced");
    } else {
      this.crud.deleteFilter("AnalysisComplete");
    }
    this.apiGetSysControl();
    this.apiGetStatus();
  },
  destroyed() {
    this.crud.clearList();
  },
  methods: {
    parseSampleDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY hh:mm A");
    },
    customQuery(record) {
      return `interfaced=${record.interfaced}`;
    },
    async apiGetStatus() {
      const resStatus = await this.axios.get(this.customUrlStatus);
      this.dataStatusFilter = [...this.dataStatusFilter, ...resStatus.data];
      this.dataStatusFilter.forEach((option) => {
        if (option.code === "all")
          return;
        option.color = SAMPLE_STATUS_COLORS[option.code];
      });
    },
    filterIsInactiveOption(option) {
      return !option.isInactive;
    },
    onShowAnalysisCompleteClick() {
      if (this.buttonType == "primary") {
        this.crud.deleteFilter("AnalysisComplete");
        this.crud.setFilter("Interfaced", { operator: "Eq", value: "false" });
      } else if (this.buttonType == "default") {
        let filterObj = { operator: "Eq", value: true };
        this.crud.setFilter("AnalysisComplete", filterObj);
        this.crud.deleteFilter("Interfaced");
      }
      this.crud.fetchList();
    },
    getStatusColor(value) {
      return SAMPLE_STATUS_CLASS_COLORS[value];
    },
    parseStatus(value) {
      return SAMPLE_STATUS_TEXTS[value];
    },
    renderIcon(value) {
      if (!value) {
        return "";
      }
      return "check-circle";
    },
    onStatusFilterChange(status) {
      if (status.value === "all") {
        this.crud.deleteFilter("Status");
        this.crud.fetchList();
        return;
      }
      this.crud.setFilter("Status", status);
      this.crud.fetchList();
    },
    async apiGetSysControl() {
      const { data } = await this.axios.get(`${this.apiUrl}/cf/syscontrols`);
      const sysControl = data.results[0];
      this.allowDeactive = sysControl.allowSampleDeleteFlag;
    },
    onEdit(record) {
      this.$router.push(`sample-processing/${record.id}?interfaced=${record.interfaced}`);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "06cb090c", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListSampleProcessing = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-sample-processing" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "cf.samples", "api-url": _vm.apiUrl, "create-route": "/container-filling/sample-processing/create", "edit-route": "/container-filling/sample-processing/:id", "filter-route": "/container-filling/sample-processing/advanced-search" } }, [_c("list-sample-processing")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListSampleProcessing
  },
  data() {
    return {
      page: CONTAINER_FILLING_SAMPLEPROCESSING,
      ListSampleProcessing,
      apiUrl,
      itemsMenu: [
        {
          key: "sample-processing",
          title: "Sample Processing",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
