const SAMPLE_STATUS_SUSPEND = "S";
const SAMPLE_STATUS_QUARANTINE = "Q";
const SAMPLE_STATUS_HOLD = "H";
const SAMPLE_STATUS_APPROVED = "A";
const SAMPLE_STATUS_COND = "C";
const SAMPLE_STATUS_COLORS = {
  [SAMPLE_STATUS_SUSPEND]: "#5C6C7F",
  [SAMPLE_STATUS_QUARANTINE]: "#0062FF",
  [SAMPLE_STATUS_HOLD]: "#FA8C16",
  [SAMPLE_STATUS_APPROVED]: "#15B982"
};
const SAMPLE_STATUS_CLASS_COLORS = {
  [SAMPLE_STATUS_SUSPEND]: "suspend",
  [SAMPLE_STATUS_QUARANTINE]: "quarantine",
  [SAMPLE_STATUS_HOLD]: "hold",
  [SAMPLE_STATUS_APPROVED]: "approved",
  [SAMPLE_STATUS_COND]: "cond"
};
const SAMPLE_STATUS_TEXTS = {
  [SAMPLE_STATUS_SUSPEND]: "Suspend",
  [SAMPLE_STATUS_QUARANTINE]: "Quarantine",
  [SAMPLE_STATUS_HOLD]: "Hold",
  [SAMPLE_STATUS_APPROVED]: "Approved",
  [SAMPLE_STATUS_COND]: "Cond"
};
export { SAMPLE_STATUS_COLORS as S, SAMPLE_STATUS_CLASS_COLORS as a, SAMPLE_STATUS_TEXTS as b };
